<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12">
          <h3>Informasi Pendonor</h3>
        </b-col>
        <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

        <b-col cols="12" class="hp-profile-content-list mt-8 pb-0 pb-sm-120">
          <h3 class="h3 mb-3">Kontak</h3>
          <div v-if="currentDataPendonor">
            <ul>
              <li class="mt-18">
                <span class="hp-p1-body">Nama Lengkap</span>
                <span
                  class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                >
                  {{ currentDataPendonor.name }}
                </span>
              </li>
              <li class="mt-18">
                <span class="hp-p1-body">Email</span>
                <span
                  class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                >
                  {{ currentDataPendonor.email }}
                </span>
              </li>
              <li class="mt-18">
                <span class="hp-p1-body">Tanggal Lahir</span>
                <span
                  class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                >
                  {{ currentDataPendonor.birth_date }}
                </span>
              </li>
              <li class="mt-18">
                <span class="hp-p1-body">Alamat</span>
                <span
                  class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                >
                  {{ currentDataPendonor.address }}
                </span>
              </li>
              <li class="mt-18">
                <span class="hp-p1-body">No. Telp</span>
                <span
                  class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
                >
                  {{ currentDataPendonor.phone }}
                </span>
              </li>
            </ul>
            <div
              v-if="currentRouteName == 'manage-user-detail'"
              class="flex justify-content-end"
            >
              <router-link
                :to="`/dashboards/user/edit-user/${this.$route.params.id}`"
              >
                <b-button variant="info" class="mt-5">
                  <i class="ri-edit-box-line mx-2" /> Edit
                </b-button>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div class="flex justify-content-center">
              <b-skeleton-table
                :rows="1"
                :columns="6"
                :table-props="{ bordered: false, striped: true }"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BSkeletonTable } from "bootstrap-vue";
import PendonorDataService from "../../../../../api/manageuser/manageUserAPI";

export default {
  name: "DetailUser",
  components: {
    BRow,
    BCol,
    BButton,
    BSkeletonTable,
  },
  data() {
    return {
      currentDataPendonor: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.getPendonor(this.$route.params.id);
  },
  methods: {
    getPendonor(id) {
      PendonorDataService.get(id).then((response) => {
        this.currentDataPendonor = response.data.data;
      });
    },
  },
};
</script>
